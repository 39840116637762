import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'gatsby'
import { Title } from '@awdis/components'

import Layout, { LayoutData } from '../../components/Layout'
import Seo from '../../components/Seo'
import BasicLink from '../../components/BasicLink'
import HubspotContactForm from '../../components/HubspotContactForm'

const StyledLink = styled(Link)`
  color: black;
  text-decoration: none;
  font-weight: bold;
`

const PageDescription = styled.p`
  margin-top: 32px;
  margin-bottom: 32px;
`

const PageFooter = styled.p`
  margin-top: 64px;
  margin-bottom: 48px;
`

type ContactEmbroidererProps = {
  pageContext: {
    layoutData: LayoutData
    page: {
      title: string
      url: string
    }
  }
}

const defaultProps = {}

const ContactEmbroiderer = function ContactEmbroiderer({
  pageContext: {
    layoutData,
    layoutData: { brandDescription },
    page,
  },
}: ContactEmbroidererProps) {
  return (
    <Layout data={layoutData}>
      <Seo
        title={page.title}
        description={brandDescription}
        type="website"
        pathname={page.url}
      />
      <Title as="h2" size="small" marginBottom="32px">
        I need a printer or embroiderer to promote my brand/club
      </Title>

      <PageDescription>
        <p>
          AWDis brands offer the widest fashion choices, colours and styles
          variations, to bring you plenty of choice when selecting stylish,
          comfortable garments for your brand. The high quality of the material
          we use ensures you receive a quality finish - making our garments the
          ideal blank canvas for your designs!
        </p>

        <p>
          To help direct you to a printer or embroiderer, please tell us a bit
          more about your brand in the form below.
        </p>

        {/* <p>
          You can also contact our Business Development Team on{' '}
          <BasicLink href={`tel:${contactNumber.tel}`}>{contactNumber.readable}</BasicLink>.
        </p> */}
      </PageDescription>

      <HubspotContactForm contactFormType="embroiderer_user" />

      <PageFooter>
        <p>
          The answers to our most common questions such as, "Where can I buy
          your products from?" & "How can I get another garment with a specific
          print?" Can be found here on our{' '}
          <StyledLink to="/faqs">FAQs page</StyledLink>
        </p>
      </PageFooter>
    </Layout>
  )
}

ContactEmbroiderer.defaultProps = defaultProps

export default ContactEmbroiderer
